document.addEventListener('DOMContentLoaded', function() {
  initFloatingFooter();
  initModal('#floatingfooter .container .button');
  initModal('#cart-sneakers .purchese__button');
});

/* ========  Start Floating Footer ======== */

function initFloatingFooter() {
  addEventListener('scroll', addShowClassToFloatingFooter, { passive: true });
}

function addShowClassToFloatingFooter() {
  let floatingFooter = document.getElementById('floatingfooter');
  if (!floatingFooter) return;
  floatingFooter.classList.add('show');
  removeEventListener('scroll', addShowClassToFloatingFooter);
}

/* ========  Start Modal ======== */

let _classCallCheck = (instance, Constructor) => {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

let _createClass = function () {
  function defineProperties(target, props) {
    props.forEach(descriptor => {
      descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    });
  }
  return (Constructor, protoProps, staticProps) => {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps); return Constructor;
  };
}();

function initModal(selector) {
  let showButton = document.querySelector(selector);
  let modalBlock = document.getElementById('modalBlock');
  if (
    !showButton ||
    !modalBlock
  ) return;
  return new ModalObject(modalBlock, 'modal__open', showButton);
}

let ModalObject = function () {
  function ModalObject(element, bodyOpenClass, trigger) {
    _classCallCheck(this, ModalObject);

    this.element = element;
    this.closeButton = this.element.getElementsByClassName('modal__close')[0];
    this.backdrop = this.element.getElementsByClassName('modal__backdrop')[0];

    this.staffComment = this.element.getElementsByClassName('info__link')[0];

    this.trigger = trigger;
    this.bodyOpenClass = bodyOpenClass;
    this.body = document.body;

    // trigger
    this.trigger.addEventListener('click', this.showModal.bind(this));
    this.closeButton.addEventListener('click', this.close.bind(this));

    this.staffComment.addEventListener('click', this.close.bind(this));

    this.backdrop.addEventListener('click', this.close.bind(this));
    this.backdrop.addEventListener('touchmove', function (event) { event.preventDefault(); });
  }

  // createClass
  _createClass(ModalObject, [{
    key: 'showModal',
    value: function showModal() {
      this.body.classList.add(this.bodyOpenClass);
      this.element.classList.add('open');
    }
  }, {
    key: 'close',
    value: function close() {
      this.element.classList.remove('open');
      this.body.classList.remove(this.bodyOpenClass);
    }
  }]);

  return ModalObject;
}();